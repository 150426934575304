.advantages {
	padding: 50px 0;
	width: 100%;
	background-color: var(--color-main-white);
}

.advantages-heading {
	text-align: center;
	margin-bottom: 40px;

	h1 {
		font-size: 52px;
		letter-spacing: -2px;
		line-height: 110%;
	}
}

.advantages-text-logo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 100px;
	flex-wrap: wrap;
}

.texts-benefits {
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
	gap: 20px;
	padding-right: 20px;
}

.benefits-paragraph {
	display: flex;
	align-items: flex-start;
	gap: 16px;

	.icon-container {
		align-items: center;
		border-radius: 28px;
		display: flex;
		flex: none;
		flex-direction: column;
		flex-wrap: nowrap;
		gap: 8px;
		height: min-content;
		justify-content: center;
		overflow: visible;
		padding: 12px;
		position: relative;
		background-color: var(--color-black);
		width: 48px;
		height: 48px;
		svg {
			color: var(--color-white);
		}
	}

	.paragraph-text {
		display: flex;
		gap: 8px;
		flex-direction: column;
		list-style-type: none;
		padding: 0;

		li:first-child p {
			font-family: "GraphikLCG-Medium", sans-serif;
			font-size: 30px;
			font-weight: 400;
			letter-spacing: -0.5px;
			line-height: 140%;
			margin-bottom: 0;
			cursor: default;
		}

		li:last-child p {
			font-family: "Manrope", sans-serif;
			font-size: 20px;
			font-weight: 500;
			letter-spacing: -0.1px;
			line-height: 140%;
			margin-bottom: 20px;
			text-align: start;
			text-transform: none;
			color: var(--color-gray);
		}
	}
}

.logo-benefits {
	flex-basis: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	.benefits-logo img {
		max-width: 100%;
		height: auto;
	}

	figure {
		max-width: 550px;
	}
}

.button-container {
	display: inline-flex;
	position: relative;
	overflow: hidden;
}

.paragraph-button {
	background-color: var(--color-black);
	border: 1px solid var(--color-black);
	border-radius: 0.7vw;
	color: var(--color-white);
	cursor: pointer;
	font-family: "Graphik LCG", sans-serif;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.5px;
	line-height: 140%;
	padding: 10px 22px;
	text-align: center;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	width: min-content;
	transition: background-color 0.2s ease, color 0.2s ease, padding 0.2s ease;

	span {
		transition: transform 0.2s ease;
	}

	.arrow-icon {
		position: absolute;
		right: 12px;
		opacity: 0;
		transform: translateX(20px);
		top: 50%;
		margin-top: -10px; // Half of the SVG height to center it vertically
		transition: opacity 0.2s ease, transform 0.2s ease;
	}

	&:hover {
		background-color: var(--color-blue-light);
		color: var(--color-black);
		padding-right: 44px; // 24px original + 20px for arrow

		span {
			transform: translateX(-10px);
		}

		.arrow-icon {
			opacity: 1;
			transform: translateX(0);
		}
	}

	&:before {
		content: "";
		position: absolute;
		right: 16px;
		top: 50%;
		margin-top: -12px;
		width: 24px;
		height: 24px;
		border: 2px solid;
		border-left-color: transparent;
		border-right-color: transparent;
		border-radius: 50%;
		opacity: 0;
		transition: opacity 0.5s ease;
		animation: 0.8s linear infinite rotate;
	}

	&.sending {
		pointer-events: none;
		cursor: not-allowed;

		&:before {
			transition-delay: 0.5s;
			opacity: 1;
		}

		&:hover {
			.arrow-icon {
				opacity: 0;
			}
		}
	}
}

@media screen and (min-width: 900px) and (max-width: 1399px) {
	.logo-benefits {
		figure {
			height: 400px;
			width: 400px;
		}
	}
	.advantages-text-logo {
		padding: 0 50px;
	}
}

@media screen and (max-width: 899px) {
	.advantages-text-logo {
		flex-direction: column;
		display: flex;
		padding: 0 50px;
	}

	.texts-benefits {
		flex-basis: 100%;
		padding-right: 0;
		margin-bottom: 40px;
		order: 2; // This will push the text content to the bottom
	}

	.logo-benefits {
		flex-basis: 100%;
		order: 1; // This will bring the logo to the top
		margin-bottom: 40px; // Add some space between logo and text
	}
	.benefits-paragraph .paragraph-text li:last-child p {
		font-size: 18px;
	}
	.logo-benefits figure {
		max-width: 450px;
	}
	.texts-benefits {
		gap: 10px;
	}
}

@media screen and (max-width: 576px) {
	.advantages {
		.logo-benefits figure {
			max-width: 400px;
		}
		.logo-benefits {
			margin-bottom: 30px;
		}
		.paragraph-text li:first-child p {
			font-size: 26px !important;
		}
		.paragraph-text li:last-child p {
			font-size: 16px !important;
		}
		.icon-container {
			width: 42px !important;
			height: 42px !important;
		}
	}
}

@media screen and (max-width: 576px) {
	.advantages-text-logo {
		padding: 0 20px;
	}
	.button-container {
		display: inline-flex;
		position: relative;
		overflow: hidden;
		flex-direction: column;
		width: 100%;
	}
	.paragraph-button {
		width: auto;
		max-width: none;
	}
	.advantages {
		padding: 30px 0;
	}
}

// 2K Resolution (2560px to 3839px)
@media only screen and (min-width: 2560px) and (max-width: 3839px) {
	.advantages {
		padding: 2.5vw 0; // 75px in 2560px
	}

	.advantages-heading {
		margin-bottom: 2.34vw; // 60px in 2560px

		h1 {
			font-size: 3.05vw; // 78px in 2560px
			letter-spacing: -0.12vw; // -3px in 2560px
		}
	}

	.advantages-text-logo {
		padding: 0 3vw; // 72px in 2560px
	}

	.texts-benefits {
		gap: 1.17vw; // 30px in 2560px
		padding-right: 1.17vw; // 30px in 2560px
	}

	.benefits-paragraph {
		gap: 0.94vw; // 24px in 2560px

		.icon-container {
			border-radius: 1.64vw; // 42px in 2560px
			padding: 0.7vw; // 18px in 2560px
			width: 2.81vw !important; // 72px in 2560px
			height: 2.81vw !important; // 72px in 2560px
		}

		.paragraph-text {
			gap: 0.47vw; // 12px in 2560px

			li:first-child p {
				font-size: 1.76vw; // 45px in 2560px
				letter-spacing: -0.029vw; // -0.75px in 2560px
			}

			li:last-child p {
				font-size: 1.4vw; // 30px in 2560px
				letter-spacing: -0.006vw; // -0.15px in 2560px
			}
		}
	}

	.logo-benefits {
		figure {
			max-width: 32.23vw; // 825px in 2560px
		}
	}

	.paragraph-button {
		font-size: 0.94vw; // 24px in 2560px
		padding: 0.59vw 1.29vw; // 15px 33px in 2560px

		&:hover {
			padding-right: 2.58vw; // 66px in 2560px
		}
	}
}

// 4K Resolution (3840px and up)
@media only screen and (min-width: 3840px) {
	.advantages {
		padding: 2.9167vw 0; // 100px in 3840px
	}

	.advantages-heading {
		margin-bottom: 2.08vw; // 80px in 3840px

		h1 {
			font-size: 2.71vw; // 104px in 3840px
			letter-spacing: -0.1vw; // -4px in 3840px
		}
	}

	.advantages-text-logo {
		padding: 0 2.5vw; // 96px in 3840px
	}

	.texts-benefits {
		gap: 1.04vw; // 40px in 3840px
		padding-right: 1.04vw; // 40px in 3840px
	}

	.benefits-paragraph {
		gap: 0.83vw; // 32px in 3840px

		.icon-container {
			border-radius: 1.46vw; // 56px in 3840px
			padding: 0.63vw; // 24px in 3840px
			width: 2.5vw !important; // 96px in 3840px
			height: 2.5vw !important; // 96px in 3840px
		}

		.paragraph-text {
			gap: 0.42vw; // 16px in 3840px

			li:first-child p {
				font-size: 1.76vw; // 60px in 3840px
				letter-spacing: -0.026vw; // -1px in 3840px
			}

			li:last-child p {
				font-size: 1.354vw; // 40px in 3840px
				letter-spacing: -0.005vw; // -0.2px in 3840px
			}
		}
	}

	.logo-benefits {
		figure {
			max-width: 28.65vw; // 1100px in 3840px
		}
	}

	.paragraph-button {
		font-size: 0.83vw; // 32px in 3840px
		padding: 0.52vw 1.15vw; // 20px 44px in 3840px

		&:hover {
			padding-right: 2.29vw; // 88px in 3840px
		}
		.arrow-icon {
			margin-top: -15px;
			right: 20px;
		}
	}
}
